import {css, html, LitElement} from 'lit';
import axios from 'axios';
import {API_BASE_URL} from "../config.js";
import { sharedDataStore } from '../reactions-datastore.js';

export class Reactions extends LitElement {
    static properties = {
        reactions: {type: Array, state: true},
        article: {type: String},
    }

    static styles = css`
        :host {
            height: 1.8rem;
            display: flex;
            align-items: center;
            opacity: 0;
            transition: opacity 0.3s ease-in-out;
        }

        :host(.hidden) {
            opacity: 0;
            pointer-events: none;
        }

        :host(.loaded) {
            opacity: 100;
            transition: opacity 0.3s ease-in-out;
        }

        /* reactions */
        .reactions {
            display: flex;
            gap: 0.2rem;
        }
        
        .reactions button {
            font-size: 1rem;
            background: transparent;
            border: none;
            cursor: pointer;
            position: relative;
            transition: transform 0.2s ease;
            overflow: visible;
        }
        
        .reactions button:hover {
            transform: scale(1.2);
        }
        
        .reactions button span.count {
            position: static;
            display: inline-block;
            margin-left: 2px;
            background: none;
            color: #333;
            border-radius: 0;
            padding: 0;
            font-size: 0.85rem;
            line-height: 1.2;
            font-weight: 500;
        }

        .floating-emoji {
          position: absolute;
          left: 50%;
          top: -80%;
          transform: translateX(-50%);
          font-size: 2rem;
          animation: floatUp 1.5s ease-out forwards;
          pointer-events: none;
        }

        @keyframes floatUp {
          0% {
            opacity: 1;
            transform: translateX(-50%) translateY(0);
          }
          100% {
            opacity: 0;
            transform: translateX(var(--x-offset, 50%)) translateY(-140px);
          }
        }
        `;

    constructor() {
        super();
        this.loaded = false;
        this.reactions = {};

        this.article = '';
    }

    willUpdate(changedProps) {
        super.willUpdate();
        if (changedProps.has('article') && this.article) {
            sharedDataStore.subscribe(reactions => {
                if (!(this.article in reactions)) {
                    this.classList.add('hidden');

                    return;
                }
                this.reactions = reactions[this.article];
                this.loaded = true;
            });
            sharedDataStore.loadData();
        }
    }

    updated(changedProps) {
        super.updated();
        if (this.loaded) {
            this.classList.add('loaded');
        }
    }

    render() {
        if (Object.keys(this.reactions).length === 0) {
            return html``;
        }

        const reactions = this.formatReactions(this.reactions);
        return html`
            <section class="reactions">
                ${reactions.map(r => html`
                    <button @click="${this._increment}" data-reaction="${r.emoji}" title="${r.tooltip}" type="button">
                ${r.emoji} <span class="count">${r.display}</span>
                </button>
                `)}
            </section>
        `;
    }

    formatReactions(reactions) {
        return Object.entries(reactions).map(([emoji, count]) => {
            let display;

            if (count > 1_000_000_000) {
                display = (count / 1_000_000_000).toFixed(1).replace(/\.0$/, '') + 'b';
            } else if (count >= 1_000_000) {
                display = (count / 1_000_000).toFixed(1).replace(/\.0$/, '') + 'm';
            } else if (count >= 1_000) {
                display = (count / 1_000).toFixed(1).replace(/\.0$/, '') + 'k';
            } else {
                display = count.toString();
            }

            return {
                emoji,
                count,
                display,
                tooltip: count.toLocaleString()
            };
        });
    }

    async _increment(e) {
        const emoji = e.currentTarget.dataset.reaction;

        this.addFloater(e, emoji);
        this.reactions = {
            ...this.reactions,
            [emoji]: this.reactions[emoji] + 1
        };

        const formData = new FormData();
        formData.append('emoji', emoji);
        formData.append('article', this.article);

        try {
            await axios.post(`${API_BASE_URL}/api/reactions/`, formData);
        } catch (error) {
            console.error('Failed to update reaction:', error);
        }
    }

    addFloater(e, emoji) {
        const floater = document.createElement('span');
        floater.className = 'floating-emoji';
        floater.textContent = emoji;

        const offsetX = Math.floor(Math.random() * 120 - 60) - 50;
        floater.style.setProperty('--x-offset', `${offsetX}%`);

        e.currentTarget.appendChild(floater);

        floater.addEventListener('animationend', () => floater.remove());
    }
}
customElements.define('article-reactions', Reactions);
