import axios from 'axios';
import {API_BASE_URL} from "./config.js";

class ReactionsDataStore {
    constructor() {
        this.data = null;
        this.subscribers = [];
        this.loaded = false;
        this.loading = false;
    }

    async loadData() {
        if (this.loaded || this.loading) return;

        this.loading = true;
        try {
            const response = await axios.get(`${API_BASE_URL}/api/reactions/`);
            this.data = response.data;
            this.loaded = true;
            this._notify();
        } catch (err) {
            console.error('Data fetch failed:', err);
        }
    }

    subscribe(callback) {
        this.subscribers.push(callback);
        // Immediately notify if data already loaded
        if (this.loaded) {
            callback(this.data);
        }
    }

    _notify() {
        this.subscribers.forEach(cb => cb(this.data));
    }
}

export const sharedDataStore = new ReactionsDataStore();